import { Component } from 'nuxt-property-decorator'
import StoreMixin from '~/mixins/store-mixin'
import validatorsPattern from '~/utils/validators'
import { PageParams, ValidatorParams } from '~/types/store/store-base-type'
import { NCalculator } from '~/types/store/calculator-micro'

@Component({
  name: 'CalculatorTypesMixin'
})
export default class CalculatorTypesMixin extends StoreMixin {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.statesReset('$calculatorTypes')
    next()
  }

  created () {
    this.$wait.end('leaveRouter')
  }

  async getCalculatorTypesList (pageParams: PageParams<NCalculator.ICalculatorType>, save = true) {
    return await this.asyncRequestHandler(
      'getCalculatorTypesList',
      this.$calculatorTypes.getList,
      pageParams,
      save
    )
  }

  async getCalculatorTypesItem (identifier: NCalculator.ICalculatorType['id']) {
    return await this.asyncRequestHandler(
      'getCalculatorTypesItem',
      this.$calculatorTypes.getItem,
      identifier
    )
  }

  async createCalculatorTypesItem () {
    return await this.asyncRequestHandler(
      'createCalculatorTypesItem',
      this.$calculatorTypes.createItem
    )
  }

  async editCalculatorTypesItem (identifier: NCalculator.ICalculatorType['id']) {
    return await this.asyncRequestHandler(
      'editCalculatorTypesItem',
      this.$calculatorTypes.editItem,
      identifier
    )
  }

  async removeCalculatorTypesItem (identifier: NCalculator.ICalculatorType['id']) {
    return await this.asyncRequestHandler(
      'removeCalculatorTypesItem',
      this.$calculatorTypes.removeItem,
      identifier
    )
  }

  typeItemValidators: ValidatorParams<NCalculator.ICalculatorType, 'name'> = {
    name: [{
      required: true,
      pattern: validatorsPattern.stringEmpty,
      message: 'Введите название',
      trigger: ['blur']
    }]
  }
}
