import { Module, Mutation } from 'vuex-module-decorators'
import { NCalculator } from '~/types/store/calculator-micro'
import BaseModule from '~/types/store/store-base'
import { ModuleConfig } from '~/types/store/store-base-type'

@Module({
  name: 'calculators',
  stateFactory: true,
  namespaced: true
})
export default class CalculatorsModule extends BaseModule<
  NCalculator.ICalculator,
  'id',
  keyof NCalculator.ICalculator
> {
  protected config: ModuleConfig<NCalculator.ICalculator, 'id'> = {
    apiUrl: 'calculator/v1/calculators',
    identifier: 'id'
  }

  protected itemData: NCalculator.ICalculator = {
    active: false,
    code: '',
    name: '',
    typeId: null
  }

  @Mutation
  resetItem (): void {
    this.itemData = {
      active: false,
      code: '',
      name: '',
      typeId: null
    }
  }
}
