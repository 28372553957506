import { Filters, ProductsPlugin, Product, ProductInput, Params, ValidatorParams } from './interfaces'
import { ResponseData } from '~/store/interfaces'

export default function getPluginApi (store:any) {
  const api: ProductsPlugin = {
    // ? ________ getters ________
    get productsList (): ResponseData<Product> {
      return store.productsList
    },

    get filters (): Filters {
      return store.filters
    },

    productById (id: number): Product | undefined {
      return store.productById(id)
    },

    get currentProduct (): Product {
      return store.currentProduct
    },

    get validators (): ValidatorParams {
      return store.validators
    },

    // ? ________ setters ________

    set productsList (products: ResponseData<Product>) {
      store.setProductsList(products)
    },

    resetProductsList () {
      store.resetProductsList()
    },

    set filters (filters: Filters) {
      store.setFilters(filters)
    },

    resetFilters () {
      store.resetFilters()
    },

    resetCurrentProduct () {
      store.resetCurrentProduct()
    },

    set currentProduct (product: Product) {
      store.setCurrentProduct(product)
    },

    // ? ________ actions ________

    getProducts (pageParams: ProductInput | null = null): Promise<ResponseData<Product>> {
      return store.getProducts(pageParams)
    },

    getProductsByGroupId (groupId: number, pageParams: ProductInput | null = null): Promise<ResponseData<Product>> {
      return store.getProductsByGroupId({ groupId, pageParams })
    },

    getProduct (id: number): Promise<Product> {
      return store.getProduct(id)
    },

    findProduct (title: string): Promise<Product[]> {
      return store.findProduct(title)
    },

    getProductsById (params: Params): Promise<ResponseData<Product>> {
      return store.getProductsById(params)
    },

    removeProduct (id: number): Promise<Product> {
      return store.removeProduct(id)
    },

    editProduct (): Promise<Product> {
      return store.editProduct()
    },

    createProduct (): Promise<Product> {
      return store.createProduct()
    },

    updateProducts (): Promise<void> {
      return store.updateProducts()
    },

    updateProductsSort (): void {
      return store.updateProductsSort()
    },

    exportZeroPriceExcel (): Promise<Blob> {
      return store.exportZeroPriceExcel()
    },

    recalculatePrices (): Promise<void> {
      return store.recalculatePrices()
    }
  }
  return api
}
